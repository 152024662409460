/* styles.css */
@media (max-width: 767px) {
  .bg-custom-position {
    background-position: calc(100% - -16rem) center;
  }
}

/* scroll btn */
a#scroll-btn {
  position: absolute;
  height: 5rem;
  width: 3.125rem;
  border: 0.25rem solid #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 3.125rem;
  border-radius: 1.5rem;
}

a#scroll-btn:before {
  position: absolute;
  content: "";
  margin: auto;
  left: 0;
  right: 0;
  top: 0.6rem;
  height: 0.6rem;
  width: 0.6rem;
  background-color: #ffffff;
  border-radius: 50%;
  animation: move-down 2s infinite;
}

@keyframes move-down {
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(2.65rem);
    opacity: 0;
  }
}

a#scroll-btn:after {
  position: absolute;
  content: "SCROLL DOWN";
  width: 8rem;
  display: block;
  text-align: center;
  left: -2.7rem;
  bottom: -2rem;
  font-size: 0.8rem;
  color: #ffffff;
  letter-spacing: 0.1875rem;
  font-weight: 600;
}

@media screen and (max-width: 48rem) {
  a#scroll-btn {
    height: 4rem;
    width: 2.5rem;
    border: 0.125rem solid #ffffff;
  }
  a#scroll-btn:before {
    height: 0.4rem;
    width: 0.4rem;
    top: 0.4rem;
  }
  a#scroll-btn:after {
    font-size: 0.6rem;
  }
}
.visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(242, 184, 122, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 4rem;
  transition: all 0.3s;
}
.glassmorphism:hover {
  box-shadow: 0 8px 32px 0 rgba(251, 146, 60, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}